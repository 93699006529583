import React from 'react'
import { Center, Flex, Spinner, SuspenseSSR } from '@vtex/store-ui'
import Layout from 'src/components/Layout'
import ColaboradorLoginView from 'src/views/colaborador'

const ColaboradorLoginPage = () => (
  <Layout>
    <SuspenseSSR
      fallback={
        <Flex sx={{ height: '18.75rem' }}>
          <Center>
            <Spinner />
          </Center>
        </Flex>
      }
    >
      <ColaboradorLoginView />
    </SuspenseSSR>
  </Layout>
)

export default ColaboradorLoginPage
