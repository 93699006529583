import type { FormEvent } from 'react'

export const cpfFormatMask = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 11
  let { value } = e.currentTarget

  value = value.replace(/[^\d]/g, '')

  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  e.currentTarget.value = value
}

export const cepFormatMask = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 9
  let { value } = e.currentTarget

  value = value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')

  e.currentTarget.value = value
}

export const phoneFormatMask = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 15
  let { value } = e.currentTarget

  value = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')

  e.currentTarget.value = value
}
