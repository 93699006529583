import React, { useRef, useState } from 'react'
import type { FormEvent } from 'react'
import {
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  Spinner,
  Text,
} from '@vtex/store-ui'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'
import { useColabStore } from 'src/hooks/useColabStore'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import { cpfFormatMask } from 'src/utils/inputFormatter'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

import * as styles from './styles'

const ColaboradorLoginView = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isInvalidUser, setInvalidUser] = useState<boolean>()

  const inputCpfRef = useRef<HTMLInputElement | null>(null)

  const { setIsCollaboratorStore } = useStoreType()
  const { buyWithoutRepresentative } = useRepresentative()
  const { setColabStore } = useColabStore()
  const { setIsLoginModalOpen } = useLoginModal()
  const profile = useProfile()

  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  if (!isAuthenticated) {
    setIsLoginModalOpen(true)
  }

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault()

    const cpfFormatted = inputCpfRef.current?.value
      .replaceAll('.', '')
      .replace('-', '')

    const profileDocument = profile?.document?.value
      .replaceAll('.', '')
      .replace('-', '')

    fetch(`/api/io/safedata/CB/search?_where=cpf=${cpfFormatted}&_fields=cpf`)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw new Error('Something went wrong')
      })
      .then((data) => {
        setIsLoading(false)

        if (cpfFormatted === profileDocument && cpfFormatted === data[0]?.cpf) {
          setColabStore()
          setIsCollaboratorStore(true)
        }

        buyWithoutRepresentative()
      })
      .catch(() => {
        setIsLoading(false)
        setInvalidUser(true)
      })
  }

  return (
    <Grid sx={styles.pageContainer}>
      <Flex sx={styles.formContainer}>
        <Heading>Acesse a sua conta, colaborador</Heading>
        <Flex
          as="form"
          onSubmit={(e) => {
            setIsLoading(true)
            handleSubmit(e)
          }}
        >
          <label htmlFor="CPF">Informe o seu CPF:</label>
          <Input
            required
            maxLength={11}
            placeholder="000.000.000-00"
            onKeyUp={cpfFormatMask}
            ref={inputCpfRef}
          />
          {isInvalidUser && (
            <Text as="span" sx={styles.invalid}>
              O CPF informado não foi encontrado em nossa base. Por gentileza,
              entre em contato com o RH através da plataforma de atendimento
              Natura &amp; Co.
            </Text>
          )}
          {isLoading ? <Spinner /> : <Button>Enviar</Button>}
        </Flex>
      </Flex>
      <Image
        src="https://avongroup.vteximg.com.br/arquivos/ids/175358/colaborador-pg-log.jpg"
        sx={styles.image}
      />
    </Grid>
  )
}

export default ColaboradorLoginView
