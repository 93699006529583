import type { SxStyleProp } from '@vtex/store-ui'

export const pageContainer: SxStyleProp = {
  margin: ['2rem auto'],
  width: ['90%', '100%'],
  gridTemplateColumns: ['1fr', '60% 40%'],
  gridTemplateRows: ['auto auto', '1fr'],
  fontFamily: ['Zona Pro, sans-serif'],
  gridGap: ['0'],
}

export const formContainer: SxStyleProp = {
  flexDirection: ['column'],
  gap: '1rem',
  width: 'fit-content',
  margin: ['auto'],
  order: ['2', '1'],

  h2: {
    fontSize: ['1.375rem', '2rem'],
    textAlign: ['center', 'left'],
  },

  form: {
    flexDirection: ['column'],
    gap: '1rem',
    width: ['100%', '21.5rem'],

    label: {
      fontWeight: 'bold',
      fontSize: ['1rem'],
    },

    input: {
      border: '1px solid #C2BFBF',
      borderRadius: '5px',
      padding: '1.25rem 0.75rem',
      fontFamily: 'Zona Pro, sans-serif',
      outline: 'none',
    },

    button: {
      background: '#EF5C99',
      color: '#FFFFFF',
      borderRadius: '5px',
      border: 'none',
      fontWeight: 'bold',
      padding: '1.25rem 0.75rem',
      cursor: 'pointer',
      fontFamily: 'Zona Pro, sans-serif',
      fontSize: '1.125rem',
    },
  },
}

export const image: SxStyleProp = {
  order: ['1', '2'],
  mb: ['2rem', 'unset'],
}

export const invalid: SxStyleProp = {
  fontSize: '0.875rem',
  color: 'red',
  fontWeight: '300',
}
